<ul nz-menu nzMode="inline" class="sider-menu">
    <li nz-menu-item [nzMatchRouter]="true" routerLink="/home">
        <span nz-icon nzType="home"></span>
        <span>Home</span>
    </li>
    <li
        *isUserType="'admin'"
        nz-submenu
        nzIcon="team"
        nzTitle="Users"
        [nzOpen]="this.openMap.users"
    >
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/users/users">List</li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/users/roles">Roles</li>
        </ul>
    </li>
    <li nz-submenu nzIcon="group" nzTitle="Accumulators" [nzOpen]="this.openMap.accumulators">
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/accumulators">
                Accumulators list
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/accumulators/add">
                New accumulator
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/accumulators/statistics">
                Statistics
            </li>
        </ul>
    </li>
    <ng-container>
        <li nz-menu-item [nzMatchRouter]="true" routerLink="/games">
            <span nz-icon nzType="global"></span>
            <span>Games</span>
        </li>
    </ng-container>
    <li
        *isUserType="'admin'"
        nz-submenu
        nzIcon="dollar-circle"
        nzTitle="Bookmakers"
        [nzOpen]="this.openMap.bookmakers"
    >
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/bookmakers/bookmakers">
                Bookmakers
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/bookmakers/holdings">
                Holdings
            </li>
            @if (canShowBookmakerMarkets) {
                <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/bookmakers/markets">
                    Markets
                </li>
            }
            <li
                nz-menu-item
                nzMatchRouter
                nzMatchRouterExact
                routerLink="/bookmakers/payment-methods"
            >
                Payment Methods
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/bookmakers/bonuses">
                Bonuses
            </li>
        </ul>
    </li>
    <ng-container *isUserType="'admin'">
        <li nz-menu-item [nzMatchRouter]="true" routerLink="/websites">
            <span nz-icon nzType="apartment"></span>
            <span>Websites</span>
        </li>
    </ng-container>
    <ng-container *isUserType="'admin'">
        <li nz-menu-item [nzMatchRouter]="true" routerLink="/competitors">
            <span nz-icon nzType="bug"></span>
            <span>Competitors</span>
        </li>
    </ng-container>
    <ng-container *isUserType="'admin'">
        <li nz-menu-item [nzMatchRouter]="true" routerLink="/data-tables">
            <span nz-icon nzType="table" nzTheme="outline"></span>
            <span>Data tables</span>
        </li>
    </ng-container>
    <li
        *isUserType="'admin'"
        nz-submenu
        nzIcon="file-done"
        nzTitle="SERP"
        [nzOpen]="this.openMap.serp"
    >
        <ul>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/serp/analyze">
                Analyze
            </li>
            <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/serp/keywords">
                Keywords list
            </li>
        </ul>
    </li>
    @if (canShowTrackingMaterial) {
        <li
            *isUserType="'admin'"
            nz-menu-item
            [nzMatchRouter]="true"
            routerLink="/tracking-material"
        >
            <span nz-icon nzType="link" nzTheme="outline"></span>
            <span>Tracking Material</span>
        </li>
    }
    @if (canShowScraping) {
        <li
            *isUserType="'admin'"
            nz-submenu
            nzIcon="bar-chart"
            nzTitle="Scraping"
            [nzOpen]="this.openMap.scraping"
        >
            <ul>
                <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/scraping/dashboard">
                    Dashboard
                </li>
                <li
                    nz-menu-item
                    nzMatchRouter
                    nzMatchRouterExact
                    routerLink="/scraping/dashboard-old"
                >
                    <s>Dashboard (old)</s>
                </li>
                <li nz-menu-item nzMatchRouter nzMatchRouterExact routerLink="/scraping/runs">
                    Runs
                </li>
            </ul>
        </li>
    }
    @if (canShowAffiliateAccount) {
        <li
            nz-submenu
            nzIcon="contacts"
            nzTitle="Aff. Accounts"
            [nzOpen]="this.openMap.affiliateAccounts"
        >
            <ul>
                <li
                    nz-menu-item
                    nzMatchRouter
                    nzMatchRouterExact
                    routerLink="/affiliate-accounts/partners"
                >
                    Partners
                </li>
                <li
                    nz-menu-item
                    nzMatchRouter
                    nzMatchRouterExact
                    routerLink="/affiliate-accounts/accounts"
                >
                    Accounts
                </li>
            </ul>
        </li>
    }
    <ng-container *isUserType="'admin'">
        <li nz-menu-item [nzMatchRouter]="true" routerLink="/cta-table-groups">
            <span nz-icon nzType="table" nzTheme="outline"></span>
            <span>CTA Table groups</span>
        </li>
    </ng-container>
</ul>
