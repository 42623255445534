import { AccumulatorService } from 'src/app/core/services/moveup-api/accumulator/accumulator.service';
import { MOVEUP_API_AFFILIATE_ACCOUNT_SERVICES } from 'src/app/core/services/moveup-api/affiliate-account';
import { AuthService } from 'src/app/core/services/moveup-api/auth/auth.service';
import { MOVEUP_API_BOOKMAKER_SERVICES } from 'src/app/core/services/moveup-api/bookmaker';
import { MOVEUP_API_COMMON_SERVICES } from 'src/app/core/services/moveup-api/common';
import { CompetitorService } from 'src/app/core/services/moveup-api/competitor/competitor.service';
import { CrudExampleService } from 'src/app/core/services/moveup-api/crud-example/crud-example.service';
import { CtaTableGroupsService } from 'src/app/core/services/moveup-api/cta-table-groups/cta-table-groups.service';
import { DataTablesService } from 'src/app/core/services/moveup-api/data-tables/data-tables.service';
import { GameService } from 'src/app/core/services/moveup-api/game/game.service';
import { ScrapingService } from 'src/app/core/services/moveup-api/scraping/scraping.service';
import { SerpService } from 'src/app/core/services/moveup-api/serp/serp.service';
import { TrackingMaterialService } from 'src/app/core/services/moveup-api/tracking-material/tracking-material.service';
import { UploadService } from 'src/app/core/services/moveup-api/upload/upload.service';
import { MOVEUP_API_USERS_SERVICES } from 'src/app/core/services/moveup-api/users';
import { WebsiteService } from 'src/app/core/services/moveup-api/website/website.service';

type OtherSportType =
    | 'cricket'
    | 'volleyball'
    | 'judo'
    | 'boxing'
    | 'tennis'
    | 'tableTennis'
    | 'beachVolleyball'
    | 'taekwondo'
    | 'handball';
export type SportType = 'soccer' | 'basketball' | OtherSportType;

export const MOVEUP_API_SERVICES = [
    AuthService,
    ...MOVEUP_API_USERS_SERVICES,
    AccumulatorService,
    GameService,
    ...MOVEUP_API_COMMON_SERVICES,
    ...MOVEUP_API_BOOKMAKER_SERVICES,
    WebsiteService,
    UploadService,
    CompetitorService,
    SerpService,
    CrudExampleService,
    DataTablesService,
    TrackingMaterialService,
    ScrapingService,
    ...MOVEUP_API_AFFILIATE_ACCOUNT_SERVICES,
    CtaTableGroupsService,
];
