import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { AFFILIATE_ACCOUNT_GUARD_ALLOWED_USER_IDS } from 'src/app/core/guards/custom-guards/affiliate-account.guard';
import { BOOKMAKER_MARKETS_GUARD_ALLOWED_USER_IDS } from 'src/app/core/guards/custom-guards/bookmaker-markets.guard';
import { SCRAPING_GUARD_ALLOWED_USER_IDS } from 'src/app/core/guards/custom-guards/scraping.guard';
import { TRACKING_MATERIAL_GUARD_ALLOWED_USER_IDS } from 'src/app/core/guards/custom-guards/tracking-material.guard';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-layout-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
    openMap = {
        accumulators: false,
        bookmakers: false,
        users: false,
        serp: false,
        scraping: false,
        affiliateAccounts: false,
    };

    canShowTrackingMaterial = false;
    canShowScraping = false;
    canShowAffiliateAccount = false;
    canShowBookmakerMarkets = false;

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
        this.authService.getUser().subscribe((user) => {
            this.canShowTrackingMaterial =
                TRACKING_MATERIAL_GUARD_ALLOWED_USER_IDS.includes(user.id) ||
                environment.bypassCustomGuards;

            this.canShowScraping =
                SCRAPING_GUARD_ALLOWED_USER_IDS.includes(user.id) || environment.bypassCustomGuards;

            this.canShowAffiliateAccount =
                AFFILIATE_ACCOUNT_GUARD_ALLOWED_USER_IDS.includes(user.id) ||
                environment.bypassCustomGuards;

            this.canShowBookmakerMarkets =
                BOOKMAKER_MARKETS_GUARD_ALLOWED_USER_IDS.includes(user.id) ||
                environment.bypassCustomGuards;
        });
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                map((event: NavigationStart) => event.url),
                startWith(this.router.url),
            )
            .subscribe((url: string) => {
                this.openMap.accumulators = RegExp('^/accumulators').test(url);
                this.openMap.bookmakers = RegExp('^/bookmakers').test(url);
                this.openMap.users = RegExp('^/users').test(url);
                this.openMap.serp = RegExp('^/serp').test(url);
                this.openMap.scraping = RegExp('^/scraping').test(url);
                this.openMap.affiliateAccounts = RegExp('^/affiliate-accounts').test(url);
            });
    }
}
