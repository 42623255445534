import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AddEditMarketDto,
    MarketNameExistsDto,
    MarketRtNameExistsDto,
} from 'src/app/core/services/moveup-api/bookmaker/market/market.dtos';
import { MarketResponseDto } from 'src/app/core/services/moveup-api/common/market/market.dtos';
import { environment } from 'src/environments/environment';

@Injectable()
export class MarketService {
    baseUrl = `${environment.api.baseUrl}/admin/bookmaker/market`;

    constructor(private http: HttpClient) {}

    addMarket(dto: AddEditMarketDto): Observable<MarketResponseDto> {
        return this.http.post<MarketResponseDto>(`${this.baseUrl}`, dto);
    }

    editMarket(marketId: string, dto: AddEditMarketDto): Observable<MarketResponseDto> {
        return this.http.put<MarketResponseDto>(`${this.baseUrl}/${marketId}`, dto);
    }

    getList(): Observable<MarketResponseDto[]> {
        return this.http.get<MarketResponseDto[]>(`${this.baseUrl}`);
    }

    getMarket(marketId: string): Observable<MarketResponseDto> {
        return this.http.get<MarketResponseDto>(`${this.baseUrl}/${marketId}`);
    }

    nameExists(dto: MarketNameExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/name-exists`, dto);
    }

    marketRtNameExists(dto: MarketRtNameExistsDto): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/rt-name-exists`, dto);
    }

    removeMarket(marketId: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${marketId}`);
    }
}
