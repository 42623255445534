import { BonusesService } from 'src/app/core/services/moveup-api/bookmaker/bonuses/bonuses.service';
import { BookmakerService } from 'src/app/core/services/moveup-api/bookmaker/bookmaker/bookmaker.service';
import { HoldingService } from 'src/app/core/services/moveup-api/bookmaker/holding/holding.service';
import { MarketService } from 'src/app/core/services/moveup-api/bookmaker/market/market.service';
import { PaymentMethodService } from 'src/app/core/services/moveup-api/bookmaker/payment-methods/payment-methods.service';

export const MOVEUP_API_BOOKMAKER_SERVICES = [
    BookmakerService,
    HoldingService,
    MarketService,
    PaymentMethodService,
    BonusesService,
];
