import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormGroup,
    UntypedFormControl,
    ValidatorFn,
} from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subject, distinctUntilChanged, fromEvent, map, merge, startWith } from 'rxjs';

const catchHttpError = (error: unknown, errorMessages: [string, string]): string | null => {
    if (error instanceof HttpErrorResponse) {
        const { status, message } = error;
        if (status === 400) {
            const mapping = errorMessages.find((item) => item[0] === message);
            if (mapping) {
                return mapping[1];
            }
        }
    }

    return null;
};

export const sleep = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

export const getHttpErrorCode = (error: unknown): string => {
    if (error instanceof HttpErrorResponse) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return error.error.error;
    }

    return null;
};

export const dtoToHttpParams = (dto: unknown): HttpParams => {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(dto)) {
        if (value) {
            if (_.isArray(value)) {
                for (const item of value) {
                    params = params.append(key, item as string);
                }
            } else {
                params = params.set(key, value as string);
            }
        }
    }

    return params;
};

export function convertToBoolProperty(val: unknown): boolean {
    if (typeof val === 'string') {
        val = val.toLowerCase().trim();

        return val === 'true' || val === '';
    }

    return !!val;
}

export const notZeroValidator: ValidatorFn = (control) => {
    if (!control.value) {
        return null;
    }
    const value: number = +control.value;

    return value === 0 ? { message: 'La valeur doit être supérieure à 0' } : null;
};

export function deviceType(): 'desktop' | 'mobile' | 'tablet' {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua,
        )
    ) {
        return 'mobile';
    }

    return 'desktop';
}

export function getCountryCode(value: string): string {
    if (value) {
        const array = value.split(' ');
        const lengthArray = array.length;
        const numberCountry = array[lengthArray - 1].substring(
            1,
            array[lengthArray - 1].length - 1,
        );

        return numberCountry;
    }

    return null;
}

export function markFormAsDirty(form: FormGroup | FormArray): void {
    Object.values(form.controls).forEach((control) => {
        if (control instanceof FormArray) {
            markFormAsDirty(control);
        } else if (control instanceof FormGroup) {
            markFormAsDirty(control);
        } else {
            if (control.invalid) {
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
            }
        }
    });
}

export type FormValidationMessages = Record<
    string,
    string | ((control: UntypedFormControl) => string)
>;

export function getFormErrors(
    control: UntypedFormControl,
    validationMessages?: FormValidationMessages,
): string[] {
    const messages: FormValidationMessages = _.defaults(validationMessages, {
        required: 'This field is required',
        email: 'Invalid email address',
        phoneNumber: 'Invalid phone number',
    });
    const errors: string[] = [];
    for (const key in control.errors) {
        if (messages) {
            const message = messages[key];
            if (!message) {
                continue;
            }
            if (typeof message === 'function') {
                errors.push(message(control));
            } else {
                errors.push(message);
            }
        }
    }

    return errors;
}

export const isHover$ = (el: ElementRef<HTMLElement>): Observable<boolean> => {
    return merge(
        fromEvent(el.nativeElement, 'mouseover').pipe(map(() => true)),
        fromEvent(el.nativeElement, 'mouseleave').pipe(map(() => false)),
    ).pipe(distinctUntilChanged(), startWith(false));
};

type BreakPointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export const activeGrids$: Observable<BreakPointType[]> = fromEvent(window, 'resize').pipe(
    startWith(undefined),
    map(() => {
        const width = window.innerWidth;
        const breakpoints: Record<BreakPointType, (w: number) => boolean> = {
            xs: (width) => width < 576,
            sm: (width) => width >= 576,
            md: (width) => width >= 768,
            lg: (width) => width >= 992,
            xl: (width) => width >= 1200,
            xxl: (width) => width >= 1600,
        };
        const results: BreakPointType[] = [];
        for (const breakpoint in breakpoints) {
            if (breakpoints[breakpoint as BreakPointType](width)) {
                results.push(breakpoint as BreakPointType);
            }
        }

        return results;
    }),
    distinctUntilChanged((previous, current) => {
        return _.isEqual(previous, current);
    }),
);

export const mobileMode$ = activeGrids$.pipe(
    map((activeGrids) => {
        return !activeGrids.includes('md');
    }),
);

export const windowWidth$ = fromEvent(window, 'resize').pipe(
    startWith(window.innerWidth),
    map(() => window.innerWidth),
);

export const breakpoint$: Observable<string> = fromEvent(window, 'resize').pipe(
    startWith(undefined),
    map(() => {
        const width = window.innerWidth;
        const breakpoints: { name: string; value: (w: number) => boolean }[] = [
            { name: 'less than XS', value: (width) => width < 480 },
            { name: 'XS to SM', value: (width) => width >= 480 && width < 576 },
            { name: 'SM to MD', value: (width) => width >= 576 && width < 768 },
            { name: 'MD to LG', value: (width) => width >= 768 && width < 992 },
            { name: 'LG to XL', value: (width) => width >= 992 && width < 1200 },
            { name: 'XL to XXL', value: (width) => width >= 1200 && width < 1600 },
            { name: 'more than XXL', value: (width) => width > 1600 },
        ];
        for (const breakpoint of breakpoints) {
            if (breakpoint.value(width)) {
                return breakpoint.name;
            }
        }

        return null;
    }),
    distinctUntilChanged(),
);

export const documentReadyStateComplete$ = new Subject<void>();
document.onreadystatechange = (): void => {
    if (document.readyState === 'complete') {
        documentReadyStateComplete$.next();
        documentReadyStateComplete$.complete();
    }
};

export const isValidUrl = (url: string): boolean => {
    try {
        new URL(url);

        return true;
    } catch (_error) {
        return false;
    }
};

export const getBaseUrl = (url: string): string => {
    try {
        const parsedUrl = new URL(url);

        return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    } catch (_error) {
        return null;
    }
};

export const forceFormValidation = (form: AbstractControl): void => {
    if (form instanceof FormGroup || form instanceof FormArray) {
        for (const inner in form.controls) {
            const control = form.get(inner);
            control && forceFormValidation(control);
        }
    } else {
        form.markAsDirty();
        form.markAsTouched();
        form.updateValueAndValidity();
    }
};
