import { AffiliateAccountGuard } from 'src/app/core/guards/custom-guards/affiliate-account.guard';
import { BookmakerMarketsGuard } from 'src/app/core/guards/custom-guards/bookmaker-markets.guard';
import { ScrapingGuard } from 'src/app/core/guards/custom-guards/scraping.guard';
import { TrackingMaterialGuard } from 'src/app/core/guards/custom-guards/tracking-material.guard';

export const CUSTOM_GUARDS = [
    TrackingMaterialGuard,
    ScrapingGuard,
    AffiliateAccountGuard,
    BookmakerMarketsGuard,
];
